<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>패치노트 등록</h5>
      </div>
      <div class="form">
        <form @submit.prevent>
          <div class="input_text">
            <label class="require">등록일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="newData.create_time"
              @change="newData.create_time = $event.target.value"
            />
          </div>
          <div class="input_text">
            <label class="require">공지업체 선택</label>
            <my-selectric
              v-show="showSelectric"
              :id="'company_selectric'"
              :watch="newData.system_company_id"
              :options="company_options_all"
              :commit="'setNewDataSystemCompanyIdToPatchNote'"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label class="require">제목</label>
            <input
              type="text"
              placeholder="제목"
              :value="newData.title"
              @input="newData.title = $event.target.value"
            />
          </div>
          <div class="contents">
            <label class="require">내용</label>
            <Editor
              :key="key"
              initialEditType="wysiwyg"
              :initialValue="newData.content"
              ref="toastuiEditor"
            />
          </div>
          <div class="btn_wrap">
            <button class="btn_sub2" @click="chkValid">
              등록
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import '@toast-ui/editor/dist/toastui-editor-only.css';
import Editor from '@/layouts/components/Editor.vue';
import MySelectric from '@/layouts/components/MySelectric';

export default {
  mixins: [DRAG_MODAL_MIXIN, FETCH_MIXIN, MODAL_MIXIN, SPINNER_MIXIN],

  components: {
    Editor,
    MySelectric,
  },
  data() {
    return {
      showSelectric: false,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      newData: 'getNewDataFromPatchNote',
      company_options_all: 'getCompanyOptionsFromPatchNote',
    }),
  },
  methods: {
    chkValid() {
      let html = this.$refs.toastuiEditor.invoke('getHTML');

      if (this.newData.create_time == '') {
        this.openOneButtonModal('등록일 입력 오류', '등록일을 입력해주세요.');
        return;
      } else if (this.newData.title.trim() == '') {
        this.openOneButtonModal('제목 입력 오류', '제목을 입력해주세요.');
        return;
      } else if (html.trim() == '') {
        this.openOneButtonModal('내용 입력 오류', '내용을 입력해주세요.');
        return;
      } else {
        this.submitForm();
      }
    },
    async submitForm() {
      let html = this.$refs.toastuiEditor.invoke('getHTML');
      this.$store.commit('setNewDataContentToPatchNote', html);

      let payload = this.lodash.clonedeep(this.newData);

      this.showSpinner();

      await this.$store
        .dispatch('INSERT_PATCH_NOTE', payload)
        .then(async () => {
          this.key = 1;
          this.$store.commit('setNewDataToPatchNote', {
            create_time: yyyymmdd(new Date()),
            title: '',
            content: '',
            system_company_id: null,
            flag: false,
          });
          await this.FETCH('FETCH_PATCH_NOTE');
          this.key = 0;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('등록 중 오류', '패치노트 등록 중 오류 발생');
          this.$store.commit('setContentToPatchNote', html);
          return;
        })
        .finally(() => {
          this.openOneButtonModal('등록 성공', '패치노트가 등록이 되었습니다.');
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.company_options_all.length < 2) {
      await this.FETCH('FETCH_ALL_SYSTEM_COMPANY');
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
