<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 4 : 3" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>등록일</th>
              <th>제목</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(patchNote, index) in patchNoteList"
              :key="patchNote.id"
              :class="selectedIndex == index ? 'active' : ''"
              @click="selectRow(index)"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ patchNote.create_time | formatDateNoHours }}</td>
              <td>{{ patchNote.title }}</td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="my_isModalOpen = true">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="form">
        <div class="input_text">
          <label class="require">등록일</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="managementData.create_time"
            @change="managementData.create_time = $event.target.value"
            :readonly="!managementMode || selectedIndex == -1"
          />
        </div>
        <div class="input_text">
          <label class="require">공지업체 선택</label>
          <div>
            <my-selectric
              v-show="showSelectric"
              :id="'company_selectric'"
              :watch="managementData.system_company_id"
              :options="company_options_all"
              :commit="'setManagementDataSystemCompanyIdToPatchNote'"
              :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
            >
            </my-selectric>
          </div>
        </div>
        <div class="input_text">
          <label class="require">제목</label>
          <input
            type="text"
            placeholder="제목"
            :value="managementData.title"
            @input="managementData.title = $event.target.value"
            :readonly="!managementMode || selectedIndex == -1"
          />
        </div>
        <div class="input_checkbox">
          <label for="checkbox2" class="label_txt">공지여부</label>
          <label
            for="checkbox2"
            class="chk_box"
            :class="managementData.flag == true ? 'active' : ''"
          >
            <i class="fa fa-check"></i>
          </label>
          <input
            type="checkbox"
            id="checkbox2"
            @click="toggleFlag"
            :disabled="!managementMode || selectedIndex == -1"
          />
        </div>
        <div class="input_text edit">
          <label class="require">내용</label>
          <div
            v-if="!managementMode || selectedIndex == -1"
            class="viewer"
            :class="{ text_in: selectedIndex != -1 }"
          >
            <Viewer
              :key="key"
              v-show="managementData.content != undefined"
              :initialValue="managementData.content"
            />
          </div>
          <div v-else class="editor">
            <Editor
              :key="key"
              initialEditType="wysiwyg"
              :initialValue="managementData.content"
              ref="toastuiEditor"
            />
          </div>
        </div>
        <div class="btn_wrap">
          <button
            class="btn_sub2"
            :disabled="!managementMode || selectedIndex == -1"
            @click="chkValid"
          >
            수정
          </button>
        </div>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="deletePatchNote"
    ></two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import FETCH_Mixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MySelectric from '@/layouts/components/MySelectric';
import { mapGetters, mapMutations } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import { formatDateNoHours } from '@/utils/filters';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import Viewer from '@/layouts/components/Viewer.vue';
import Editor from '@/layouts/components/Editor.vue';

export default {
  mixins: [ModalMixin, SpinnerMixin, FETCH_Mixin],
  components: {
    TwoButtonModal,
    MySelectric,
    Editor,
    Viewer,
  },
  data() {
    return {
      //modal
      my_modal_title: '경고',
      my_modal_content: '정말로 삭제하시겠습니까?',
      my_modal_index: this.selectedIndex,
      my_isModalOpen: false,

      showSelectric: false,
      key: 0,
      touchFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedIndex: 'getSelectIndexFromPatchNote',
      managementMode: 'getManagementModeFromPatchNotePage',
      patchNoteList: 'getPatchNoteListFromPatchNote',
      company_options_all: 'getCompanyOptionsFromPatchNote',
      managementData: 'getmanagementDataFromPatchNote',
    }),
  },
  methods: {
    ...mapMutations({ toggleFlag: 'toggleManagementDataFlagToPatchNote' }),

    selectRow(index) {
      this.$store.commit('setSelectIndexToPatchNote', index);
      if (index != -1) {
        let manageDataClone = this.lodash.clonedeep(this.patchNoteList[index]);
        manageDataClone.create_time = formatDateNoHours(
          manageDataClone.create_time,
        );
        this.$store.commit('setManagementDataToPatchNote', manageDataClone);
        this.key++;
      } else {
        this.$store.commit('setManagementDataToPatchNote', {
          id: -1,
          create_time: yyyymmdd(new Date()),
          title: '',
          content: '',
          system_company_id: null,
          flag: false,
        });
        this.key++;
      }
    },
    chkValid() {
      let contentHtml = this.$refs.toastuiEditor.invoke('getHTML');
      if (this.managementData.create_time == '') {
        this.openOneButtonModal('등록일 입력 오류', '등록일을 입력해주세요.');
        return;
      } else if (this.managementData.title.trim() == '') {
        this.openOneButtonModal('제목 입력 오류', '제목을 입력해주세요.');
        return;
      } else if (contentHtml.trim() == '') {
        this.openOneButtonModal('내용 입력 오류', '내용을 입력해주세요.');
        return;
      } else {
        this.submitForm(contentHtml);
      }
    },
    async submitForm(contentHtml) {
      // let contentHtml = this.$refs.toastuiEditor.invoke('getHTML');
      this.$store.commit('setManagementDataContentToPatchNote', contentHtml);

      let payload = this.lodash.clonedeep(this.managementData);

      this.showSpinner();

      await this.$store
        .dispatch('UPDATE_PATCH_NOTE', payload)
        .then(async () => {
          this.$store.commit('setManagementDataToPatchNote', {
            id: -1,
            create_time: yyyymmdd(new Date()),
            title: '',
            content: 'test',
            system_company_id: null,
            flag: false,
          });
          this.$store.commit('setSelectIndexToPatchNote', -1);
          await this.FETCH('FETCH_PATCH_NOTE');
          this.key++;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('수정 중 오류', '패치노트 수정 중 오류 발생');
          return;
        })
        .finally(() => {
          this.openOneButtonModal('수정 성공', '패치노트가 수정이 되었습니다.');
          this.hideSpinner();
        });
    },
    async deletePatchNote() {
      this.showSpinner();

      await this.$store
        .dispatch(
          'DELETE_PATCH_NOTE',
          this.patchNoteList[this.selectedIndex].id,
        )
        .then(async () => {
          this.$store.commit('setSelectIndexToPatchNote', -1);
          await this.FETCH('FETCH_PATCH_NOTE');
          this.selectRow(this.selectedIndex);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('삭제 중 오류', '패치노트 삭제 중 오류');
        })
        .finally(() => {
          this.my_isModalOpen = false;
          this.openOneButtonModal('삭제 성공', '패치노트가 삭제되었습니다.');
          this.hideSpinner();
        });
    },
  },
  async created() {
    await this.FETCH('FETCH_PATCH_NOTE');
    if (this.company_options_all.length < 2) {
      await this.FETCH('FETCH_ALL_SYSTEM_COMPANY');
    }

    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
