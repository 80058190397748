<template>
  <div
    id="contents"
    :class="{
      patch_note_list: openTabIndex == 0,
      patch_note_register: openTabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>패치노트관리</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToPatchNotePage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <!-- <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          > -->
          <a
            class="btn_admin"
            :class="managementMode ? 'on' : ''"
            @click="ToggleManagementMode"
            v-show="isPermissionOn && openTabIndex == 0"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: openTabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">조회&수정</a>
          </li>
          <li :class="{ active: openTabIndex == 1 }" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">등록</a>
          </li>
        </ul>
      </div>
      <patch-note-modification-form
        v-if="openTabIndex == 0"
      ></patch-note-modification-form>
      <patch-note-new-form v-if="openTabIndex == 1"></patch-note-new-form>
    </div>
  </div>
</template>

<script>
import PatchNoteModificationForm from '@/views/forms/PatchNote/PatchNoteModificationForm.vue';
import PatchNoteNewForm from '@/views/forms/PatchNote/PatchNoteNewForm.vue';
import { mapGetters, mapMutations } from 'vuex';
import ClosePageMixin from '@/mixins/closePage';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import FavoriteMixin from '@/mixins/favorite';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    PatchNoteModificationForm,
    PatchNoteNewForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      openTabIndex: 'getOpenTabIndexFromPatchNotePage',
      managementMode: 'getManagementModeFromPatchNotePage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPatchNotePage',
      ToggleManagementMode: 'toggleManagementModeToPatchNotePage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPatchNotePageStore');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
